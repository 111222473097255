import FrontText from "../FrontText";

const FrontDotsDecoration = ({ className = "" }) => {
    return (
        <div className={`${className}`}>
            <FrontText textSize={"17/21"}>
                <span className="pointer-events-none font-black font-inter text-tango-red tracking-[7.31px] select-none">
                    ........
                    <br />
                    ........
                    <br />
                    ........
                    <br />
                    ........
                </span>
            </FrontText>
        </div>
    );
};

export default FrontDotsDecoration;
